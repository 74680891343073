/* eslint-disable */
import {
    SIMPLE,
    CONFIGURABLE
} from 'Util/Product';
import { getAddedToCartData } from '../util/Klaviyo';

const registerAddedToCart = (args, callback, instance) => {
    callback.apply(instance, args);

    const {
        addedToCartEvent
    } = instance.props;

    if (!addedToCartEvent) return;

    const {
        product: {
            type_id = ''
        } = {}
    } = instance.props;

    if (!(type_id === SIMPLE || type_id === CONFIGURABLE)) return;

    pushAddedToCart(instance.props);
};

const pushAddedToCart = (props) => {
    const _learnq = window._learnq || [];

    const data = getAddedToCartData(props);

    _learnq.push(['track', 'Added to Cart', data]);
}

const mapStateToPropsAddToCartContainer = (args, callback, instance) => {
    const [state] = args;

    return {
        ...callback.apply(instance, args),
        baseLinkUrl: state.ConfigReducer.base_link_url,
        addedToCartEvent: state.ConfigReducer.klaviyo_events_added_to_cart,
        cartGrandTotal: state.CartReducer?.cartTotals?.grand_total,
        cartItems: state.CartReducer?.cartTotals?.items
    }
}

export default {
    'Component/AddToCart/Container': {
        'member-function': {
            'afterAddToCart': registerAddedToCart
        }
    },
    'Component/AddToCart/Container/mapStateToProps': {
        'function': mapStateToPropsAddToCartContainer
    }
}