/* eslint-disable */
import ProductGallery from 'Component/ProductGallery';
import ProductActions from 'Component/ProductActions';
import {
    AMOUNT_OF_PLACEHOLDERS,
    IMAGE_TYPE,
    THUMBNAIL_KEY
} from 'Component/ProductGallery/ProductGallery.config';

const replaceGetProductGalleryPictures = (args, callback, instance) => {
    const [product] = args;
    const {
        areDetailsLoaded
    } = instance.props;
    const {
        media_gallery_entries: mediaGallery = [],
        [THUMBNAIL_KEY]: { url } = {},
        name
    } = product;
    if (mediaGallery.length) {
        return mediaGallery
            .filter(({ disabled }) => !disabled)
            .sort((a, b) => {
                const aThumbnail = a.types.includes(THUMBNAIL_KEY);
                const bThumbnail = b.types.includes(THUMBNAIL_KEY);
                const sortResult = a.position - b.position;

                if (aThumbnail && bThumbnail) {
                    return sortResult;
                }

                if (aThumbnail) {
                    return -1;
                }

                if (bThumbnail) {
                    return 1;
                }

                return sortResult;
            });
    }

    if (!url) {
        return Array(AMOUNT_OF_PLACEHOLDERS + 1).fill({ media_type: 'placeholder' });
    }

    const placeholders = !areDetailsLoaded
        ? Array(AMOUNT_OF_PLACEHOLDERS).fill({ media_type: 'placeholder' }) : [];

    return [
        {
            thumbnail: { url },
            base: { url },
            id: THUMBNAIL_KEY,
            label: name,
            media_type: IMAGE_TYPE
        },
        ...placeholders
    ];
}

const replaceGetGalleryPictures = (args, callback, instance) => {
    const { product, dataSource } = instance.props;
    const { media_gallery_entries: mediaGallery = []} = product;
    if (mediaGallery.length){
        return instance.getProductGalleryPictures(product);
    }
    return instance.getProductGalleryPictures(dataSource);
}

const replaceGetProductThumbnail = (args, callback, instance) => {
    const { item: { product } } = instance.props;
    const variantIndex = instance._getVariantIndex();
    
    const productOrVariant = variantIndex < 0
    ? product
    : product.variants[variantIndex];
    
    const { thumbnail: { url: thumbnail, path  } = {} } = productOrVariant;
    
    if ((!path || path === 'no_selection' ) && variantIndex >= 0){
        const { thumbnail: { url: defaultThumbnail  } = {} } = product;
        return defaultThumbnail || '';
    }
    return thumbnail || '';
}

const replaceRenderProductPageContent = (args, callback, instance) => {
    const {
        configurableVariantIndex,
        parameters,
        getLink,
        dataSource,
        updateConfigurableVariant,
        productOrVariant,
        areDetailsLoaded,
        getSelectedCustomizableOptions,
        productOptionsData,
        setBundlePrice,
        selectedBundlePrice,
        selectedBundlePriceExclTax,
        setLinkedDownloadables,
        setLinkedDownloadablesPrice,
        selectedLinkPrice,
        device
    } = instance.props;
    return (
        <>
            <ProductGallery
              dataSource={ dataSource }
              product={ productOrVariant }
              areDetailsLoaded={ areDetailsLoaded }
            />
            { instance.renderProductCompareButton() }
            <div>
            <ProductActions
              getLink={ getLink }
              updateConfigurableVariant={ updateConfigurableVariant }
              product={ dataSource }
              productOrVariant={ productOrVariant }
              parameters={ parameters }
              areDetailsLoaded={ areDetailsLoaded }
              configurableVariantIndex={ configurableVariantIndex }
              getSelectedCustomizableOptions={ getSelectedCustomizableOptions }
              productOptionsData={ productOptionsData }
              setBundlePrice={ setBundlePrice }
              selectedBundlePrice={ selectedBundlePrice }
              selectedBundlePriceExclTax={ selectedBundlePriceExclTax }
              setLinkedDownloadables={ setLinkedDownloadables }
              setLinkedDownloadablesPrice={ setLinkedDownloadablesPrice }
              selectedLinkPrice={ selectedLinkPrice }
            />
            { device.isMobile ? null : instance.renderProductTabs() }
            </div>
        </>
    );
}

const replaceGetThumbnail = (args, callback, instance) => {
    const product = instance._getProductOrVariant();
    const { small_image: { url, path } = {} } = product;
    if (instance._isThumbnailAvailable(path)) {
        return url;
    }

    // If thumbnail is, missing we try to get image from parent
    const { product: { small_image: { url: parentUrl } = {} } } = instance.props;
    if (instance._isThumbnailAvailable(parentUrl)) {
        return parentUrl;
    }

    return '';
}

export default {
    'Component/CartItem/Container': {
        'member-function': {
            '_getProductThumbnail': replaceGetProductThumbnail
        }
    },
    'Route/ProductPage/Component': {
        'member-function': {
            'renderProductPageContent': replaceRenderProductPageContent
        }
    },
    'Component/ProductGallery/Container': {
        'member-function': {
            'getProductGalleryPictures': replaceGetProductGalleryPictures,
            'getGalleryPictures': replaceGetGalleryPictures
        }
    },
    'Component/ProductCard/Container': {
        'member-function': {
            '_getThumbnail': replaceGetThumbnail
        }
    }
}