/* eslint-disable */

// import Header from 'SourceComponent/Header/Header.component';
// import './Header.override.style.scss';
// export default Header;

import { ProductWishlistButton as HoeksProductWishlistButton } from 'HoeksComponent/ProductWishlistButton/ProductWishlistButton.component';

export class ProductWishlistButton extends HoeksProductWishlistButton {
    render() {
        return null;
    }
}

export default ProductWishlistButton;