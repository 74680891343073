/* eslint-disable */
import { getStartedCheckoutData } from '../util/Klaviyo';

const registerStartedCheckout = (args, callback, instance) => {
    callback.apply(instance, args);

    const {
        startedCheckoutEvent
    } = instance.props;

    if (!startedCheckoutEvent) return;

    pushStartedCheckout(instance.props);
}

const pushStartedCheckout = (props) => {
    const _learnq = window._learnq || [];

    const data = getStartedCheckoutData(props);

    _learnq.push(['track', 'Started Checkout', data]);
}

const mapStateToPropsCheckoutContainer = (args, callback, instance) => {
    const [state] = args;

    return {
        ...callback.apply(instance, args),
        baseLinkUrl: state.ConfigReducer.base_link_url,
        startedCheckoutEvent: state.ConfigReducer.klaviyo_events_started_checkout
    }
}

export default {
    'Route/Checkout/Container': {
        'member-function': {
            'componentDidMount': registerStartedCheckout
        },
    },
    'Route/Checkout/Container/mapStateToProps': {
        'function': mapStateToPropsCheckoutContainer
    } 
}