// import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import Field from 'Component/Field';
import { BILLING_STEP } from 'Route/Checkout/Checkout.config';
import FormPortal from 'Component/FormPortal';

import './EanFields.style';

/** @namespace Hoeks/Tricean/Component/EanFields/Component/EanFieldsComponent */
export class EanFieldsComponent extends PureComponent {
    static propTypes = {
        // TODO: implement prop-types
    };
    

    onChange = () => {

    }

    renderConent(){
        const { onReqChange, onEanChange, ean_number, req_number } = this.props;

        return (
            <FormPortal id={BILLING_STEP} name="eanpayment">
                <Field
                    type="text"
                    label={ __('EAN Number') }
                    id="ean_number"
                    name="ean_number"
                    validation={['notEmpty', 'eanNumber']}
                />
                <Field
                    type="text"
                    label={ __('VAT Number') }
                    id="vat_id"
                    name="vat_id"
                    validation={['notEmpty']}
                />
                <Field
                    type="text"
                    label={ __('Requisition Number') }
                    id="requisition_number"
                    name="requisition_number"
                    validation={['notEmpty']}
                />
            </FormPortal>
        );
    }

    render() {
        return (
            <div block="EanFields">
                { this.renderConent() }
            </div>
        );
    }
}

export default EanFieldsComponent;
