import CategoryMenuReducer from '../store/CategoryMenu/CategoryMenu.reducer';

export const aroundGetStaticReducers = (args, callback) => ({
    ...callback(...args),
    CategoryMenuReducer
})

export default {
    'Store/Index/getReducers': {
        'function': aroundGetStaticReducers
    }
};
