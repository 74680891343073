/* eslint-disable */
const sortOption = (args, callback, instance) => {
    const [option] = args;
    option.attribute_values.sort((a, b) => (option.attribute_options[a].label > option.attribute_options[b].label) ? 1 : -1);
    return callback(option);
}

const renderStringValue = (args, callback, instance) => {
    const [value, label] = args;
    const { isFormattedAsText, isSelected } = instance.props;
    const isSwatch = label;

    if (isFormattedAsText) {
        return label || value || __('N/A');
    }

    if (!isSwatch) {
        return instance.renderDropdown(value);
    }

    return (
        <span
            block="ProductAttributeValue"
            elem="String"
            mods={ { isSelected } }
            title={ label }
        >
            { value ?? label }
        </span>
    );
}

export default {
    // 'Component/ProductConfigurableAttributes/Component': {
    //     'member-function': {
    //         'renderSwatch': sortOption,
    //         'renderDropdown': sortOption
    //     }
    // },
    // 'Component/ProductAttributeValue/Component': {
    //     'member-function': {
    //         'renderStringValue': renderStringValue
    //     }
    // }
}