// import PropTypes from 'prop-types';
import CartSuccessPixelHtmlQuery from 'Query/CartSuccessPixelHtml.query';
import DataContainer from 'Util/Request/DataContainer';

import CartSuccessPixelHtmlComponent from './CartSuccessPixelHtml.component';

/** @namespace HoeksPwa/Component/CartSuccessPixelHtml/Container/CartSuccessPixelHtmlContainer */
export class CartSuccessPixelHtmlContainer extends DataContainer {
    state = {
        image: [],
        script: ''
    };

    __construct(props) {
        super.__construct(props);

        this.requestCartSuccessPixelHtml();
    }

    requestCartSuccessPixelHtml() {
        this.fetchData(
            [CartSuccessPixelHtmlQuery.getCartSuccessPixelHtmlQuery()],
            (result) => {
                this.setState({
                    image: result.getCartSuccessPixelHtml.image,
                    script: result.getCartSuccessPixelHtml.script
                });
            },
            // eslint-disable-next-line no-console
            (e) => console.log(e)
        );
    }

    render() {
        const { image, script } = this.state;

        return (
            <CartSuccessPixelHtmlComponent image={ image } script={ script } />
        );
    }
}

export default CartSuccessPixelHtmlContainer;
