/* eslint-disable */

const reloadAddWish = (args, callback, instance) => {
    if(typeof ADDWISH_PARTNER_NS !== 'undefined') {
        ADDWISH_PARTNER_NS.api.reload();
    }
}

// export default {
//     'Route/CategoryPage/Component': {
//         'member-function': {
//             'renderCmsBlock': afterRenderCmsBlock
//         }
//     }
// }

export default {
    'Route/HomePage/Container': {
        'member-function': {
            'componentDidMount': reloadAddWish
        }
    },
    // 'Route/CmsPage/Container': {
    //     'member-function': {
    //         'componentDidMount': reloadAddWish
    //     }
    // },
    'Route/ProductPage/Component': {
        'member-function': {
            'componentDidUpdate': reloadAddWish
        }
    },
    'Route/CartPage/Component': {
        'member-function': {
            'componentDidUpdate': reloadAddWish
        }
    },
    'Route/SearchPage/Container': {
        'member-function': {
            'componentDidUpdate': reloadAddWish
        }
    },
    'Route/MyAccount/Container': {
        'member-function': {
            'componentDidUpdate': reloadAddWish
        }
    },
    'Route/ContactPage/Container': {
        'member-function': {
            'componentDidUpdate': reloadAddWish
        }
    },
    'Route/CategoryPage/Component': {
        'member-function': {
            'renderCategoryDetails': reloadAddWish
        }
    }
}
