/* eslint-disable */

import './Fallback.style';

/** @namespace Component/Fallback/Component */
export class Fallback extends PureComponent {
    render() {
        const {
            width,
            height
        } = this.props;

        return (
            <div
                block="Fallback"
                style={ { width: `${width}px`, height: `${height}px` } }
            />
        )
    }
}

export default Fallback