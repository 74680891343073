/* eslint-disable */
import KlaviyoFormWidget from '../component/KlaviyoFormWidget';

const KLAVIYO_FORM = 'KlaviyoForm';
const addKlaviyoFormWidget = (member, instance) => {

    return {
        ...member,
        [KLAVIYO_FORM]: {
            component: KlaviyoFormWidget
        }
    }
}

export default {
    'Component/WidgetFactory/Component': {
        'member-property': {
            'renderMap': addKlaviyoFormWidget
        }
    },
}