/* eslint-disable */
const config = (member, instance) => {
    return {
        sortKey: 'Hot',
        sortDirection: 'ASC'
    };
}
export default {
    'Route/CategoryPage/Container': {
        'member-property': {
            config: config
        }
    }
}