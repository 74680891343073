/* eslint-disable */

// imports the original component from vendor folder
import { ProductCompareButton as SourceProductCompareButton } from 'SourceComponent/ProductCompareButton/ProductCompareButton.component';

export class ProductCompareButton extends SourceProductCompareButton { 

    // Overwrite render of compare button to completely hide it
    render() {
        return (
            <></>
        );
    }
}

export default ProductCompareButton;
