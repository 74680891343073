/* eslint-disable */
 import { PureComponent } from 'react';

 /** @namespace Component/NewVersionPopup/Container */
 export class NewVersionPopupContainer extends PureComponent {
     render() {
         return (
             null
         );
     }
 }
 
 export default NewVersionPopupContainer;
 