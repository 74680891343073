/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
// import PropTypes from 'prop-types';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Html from 'Component/Html';

import './CartSuccessPixelHtml.style';

/** @namespace HoeksPwa/Component/CartSuccessPixelHtml/Component/CartSuccessPixelHtmlComponent */
export class CartSuccessPixelHtmlComponent extends PureComponent {
    static propTypes = {
        image: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
        script: PropTypes.string.isRequired
    };

    render() {
        const { image, script } = this.props;

        return (
            <div block="CartSuccessPixelHtml">
                <img src={ image.src } alt={ image.alt } />
                <Html content={ script } />
            </div>
        );
    }
}

export default CartSuccessPixelHtmlComponent;
