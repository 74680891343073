/* eslint-disable */
const addKlaviyoConfig = (args, callback, instance) => {
    return [
        ...callback.apply(instance, args),
        'klaviyo_general_script',
        'klaviyo_general_brand_attribute',
        'klaviyo_events_viewed_product',
        'klaviyo_events_added_to_cart',
        'klaviyo_events_started_checkout'
    ]
}

export default {
    'Query/Config': {
        'member-function': {
            '_getStoreConfigFields': addKlaviyoConfig
        }
    }
}