import {
    UPDATE_CATEGORIES,
    UPDATE_CATEGORIES_TOP_LEVEL
} from './CategoryMenu.action';

/** @namespace Store/CategoryMenu/Reducer/getInitialState */
export const getInitialState = () => ({
    categories: {},
    id: 0
});

/** @namespace Store/CategoryMenu/mostPopularReducer */
export const CategoryMenuReducer = (
    state = getInitialState(),
    action
) => {
    const { type, categories, category } = action;
    switch (type) {
        case UPDATE_CATEGORIES:
            categories.sort((a, b) => a.level-b.level)

            let current = categories[categories.length-1];
            const id = current.id;
            let i;
            for (i = categories.length-1; i>0; i--){
                current.children.sort((a, b) => a.position-b.position);
                current = categories[i-1];
                current.children[current.children.findIndex((cat) => cat.id == categories[i].id)] = categories[i];
            }
            categories[0].children.sort((a, b) => a.position-b.position);

            return {
                ...state,
                categories: categories[0],
                id: id
            }
        case UPDATE_CATEGORIES_TOP_LEVEL:
            category.children.sort((a, b) => a.position-b.position);
            return {
                ...state,
                categories: category,
                id: category.id
            }
        default:
            return state;
    }
}

export default CategoryMenuReducer;