import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { CategoryTreeType } from 'Type/Category';
import Link from 'Component/Link';

import './CategoryMenuItem.style';

/** @namespace Component/CategoryMenuItem/Component */
export class CategoryMenuItem extends PureComponent {
    static propTypes = {
        category: CategoryTreeType.isRequired,
        isActive: PropTypes.bool
    }

    static defaultProps = {
        isActive: false,
        category: {}
    }


    renderSubcategory = category => {
        return (<CategoryMenuItem key={category.id} category={category} active={this.props.active} />);
    }

    renderSubcategories(){
        const { category: { children } } = this.props;
        if (!children){
            return null;
        }
        return (
            <div block="CategoryMenuItem" elem="SubCategories">
                { children.map(this.renderSubcategory) }
            </div>
        )
    }

    render(){
        const { category: { id, name, url, children_count = 0, children, include_in_menu }, active = 0 } = this.props;

        if (!url || !include_in_menu){
            return null;
        }

        return(
            <div block="CategoryMenuItem" elem="Item">
                <Link block="CategoryMenuItem" elem="Link" mods={{active: active === id, activeParent: children && (active !== id), isParent: children_count>0 }} to={url}>{ name }</Link>
                { this.renderSubcategories()}
            </div>
        )
    }
}

export default CategoryMenuItem;
