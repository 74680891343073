/* eslint-disable */

import CmsBlock from 'Component/CmsBlock';
// imports the original component from vendor folder
import { Footer as SourceFooter } from 'SourceComponent/Footer/Footer.component';

import { NEWSLETTER_COLUMN } from './Footer.config';
import CartSuccessPixelHtml from 'Component/CartSuccessPixelHtml';

// import new extended styles file
import './Footer.override.style.scss';

export class Footer extends SourceFooter {
    renderCopyrightContent() {
        return (
            <CmsBlock identifier={ "footer-copyright" } />
        );
    }

    renderCmsBlockWrapper() {
        const { footer_content: { footer_cms } = {} } = window.contentConfiguration;

        return (
            <div
              block="Footer"
              elem="CmsBlockWrapper"
              mix={ { block: 'Footer', elem: 'Content' } }
            >
                <div
                  block="Footer"
                  elem="Columns"
                  mix={ { block: 'ContentWrapper' } }
                >
                    <CmsBlock identifier={ footer_cms } />
                </div>
            </div>
        );
    }

    render() {
        
        return (
            <footer block="Footer" aria-label="Footer Area">
                { this.renderContent() }
                { this.renderCopyrightContent() }
            </footer>
        );
    }

}

export default Footer;
