/* eslint-disable */
import { PureComponent } from 'react';
import Html from 'Component/Html';
import './KlaviyoFormWidget.style';

/** @namespace Component/KlaviyoFormWidget/Component */
export class KlaviyoFormWidget extends PureComponent {
    render() {
        const { form } = this.props;

        return (
            <div className={ form } />
        )
    }
}

export default KlaviyoFormWidget;