/* eslint-disable */
import KlaviyoForms from './KlaviyoForms.component';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

/** @namespace Component/KlaviyoForms/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    script: state.ConfigReducer.klaviyo_general_script
});

/** @namespace Component/KlaviyoForms/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({});

/** @namespace Component/KlaviyoForms/Container */
export class KlaviyoFormsContainer extends PureComponent {
    static defaultProps = {
        script: null
    }

    render() {
        return (
            <KlaviyoForms
                { ...this.props }
            />
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(KlaviyoFormsContainer);