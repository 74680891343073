/* eslint-disable */

// imports the original component from vendor folder
import { ProductGallery as SourceProductGallery }
    from 'SourceComponent/ProductGallery/ProductGallery.component';
// import new extended styles file
import './ProductGallery.base.override.style.scss';
import { withRouter } from 'react-router';

export default withRouter(SourceProductGallery);
