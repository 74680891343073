/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import CartSuccessPixelHtml from 'Component/CartSuccessPixelHtml';
import { CheckoutSuccess as SourceCheckoutSuccess } from 'SourceComponent/CheckoutSuccess/CheckoutSuccess.component';

/** @namespace HoeksPwa/Component/CheckoutSuccess/Component/CheckoutSuccessComponent */
export class CheckoutSuccessComponent extends SourceCheckoutSuccess {
    renderPixelHtml() {
        return <CartSuccessPixelHtml />;
    }

    render() {
        const { orderID } = this.props;

        return (
            <div block="CheckoutSuccess">
                <h3>{ __('Your order # is: %s', orderID) }</h3>
                <p>{ __('We`ll email you an order confirmation with details and tracking info.') }</p>
                { this.renderButtons() }
                { this.renderPixelHtml() }
            </div>
        );
    }
}

export default CheckoutSuccessComponent;
