/* eslint-disable */
import HoeksMenuOverlayComponent from 'HoeksComponent/MenuOverlay/MenuOverlay.component';
import Overlay from 'Component/Overlay';
import Menu from 'SourceComponent/Menu/Menu.component';

/** @namespace Component/MenuOverlay/Component */
export class MenuOverlayComponent extends HoeksMenuOverlayComponent {
    render() {
        const { isActive } = this.props;

        return (
            <Overlay
              id="menu"
              mix={ { block: 'MenuOverlay', mods: { isActive } } }
            >
                <article
                  block="MenuOverlay"
                  elem="Results"
                  aria-label="Search results"
                >
                    { Menu.prototype.render.call(this) }
                </article>
            </Overlay>
        );
    }
}

export default MenuOverlayComponent;
