import { Field } from 'Util/Query';

/** @namespace HoeksPwa/Query/CartSuccessPixelHtml/Query/CartSuccessPixelHtmlQuery */
export class CartSuccessPixelHtmlQuery {
    getCartSuccessPixelHtmlQuery() {
        return new Field('getCartSuccessPixelHtml')
            .addField('script')
            .addField(this.getImageField());
    }

    getImageField() {
        return new Field('image')
            .addFieldList(this.getImageFields());
    }

    getImageFields() {
        return [
            'src',
            'alt'
        ];
    }
}

export default new CartSuccessPixelHtmlQuery();
