import CategoryMenu from '../component/CategoryMenu';

export class CategoryPageComponentPlugin {
    beforeRenderFilterOverlay = (args, callback, instance) => {
        return (
            <>
                <CategoryMenu />
                { callback(...args) }
            </>
        );
    }

    hideCategoryOptions = (args, callback, instance) => {

        const [option] = args;
        if (option.attribute_code == "category_id"){
            return null;
        }
        return callback(...args);

    }
}

const {
    beforeRenderFilterOverlay,
    hideCategoryOptions
} = new CategoryPageComponentPlugin();


export const config = {
    'Component/CategoryConfigurableAttributes/Component': {
        'member-function': {
            renderConfigurableAttributes: beforeRenderFilterOverlay,
            renderDropdownOrSwatch: hideCategoryOptions
        }
    }
};

export default config;
