/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

/* eslint-disable no-restricted-syntax */
/* eslint-disable react/no-array-index-key */
/* eslint-disable */
// Disabled due placeholder needs

import ProductActionsSource from 'SourceComponent/ProductActions/ProductActions.component';
import TextPlaceholder from 'Component/TextPlaceholder';
import './ProductActions.base.style.scss';

export class ProductActions extends ProductActionsSource {
    renderNameAndBrand() {
        const {
            product:
                {
                    name,
                    attributes: { brand: { attribute_value: brand } = {} } = {}
                },
            showOnlyIfLoaded
        } = this.props;

        return (
            <section
              block="ProductActions"
              elem="Section"
              mods={ { type: 'name' } }
            >
                { showOnlyIfLoaded(
                    brand,
                    (
                        <>
                            <meta itemProp="brand" content={ brand } />
                            <h4 block="ProductActions" elem="Brand" itemProp="brand">
                                <TextPlaceholder content={ brand } />
                            </h4>
                        </>
                    )
                ) }
                <h1 block="ProductActions" elem="Title" itemProp="name">
                    <TextPlaceholder content={ name } length="medium" />
                </h1>
            </section>
        );
    }

}

export default ProductActions;
