/* eslint-disable */
import { Router as SourceRouter } from 'SourceComponent/Router/Router.component';
import {
    BEFORE_ITEMS_TYPE
} from 'Component/Router/Router.config';
import NavigationTabs from 'Component/NavigationTabs';
import history from 'Util/History';

export * from 'SourceComponent/Router/Router.component';

export class Router extends SourceRouter {
    [BEFORE_ITEMS_TYPE] = [
        ...this.BEFORE_ITEMS_TYPE.filter(({ component: { type } }) => type !== NavigationTabs)
    ]

    componentDidMount(){
        const {
            location: {
                pathname = ''
            } = {}
        } = history;

        window.hoeksLastPathname = pathname;

        history.listen((history, type) => {
            const {
                pathname
            } = history;
            if (type === "PUSH" && pathname !== window.hoeksLastPathname) {
                window.hoeksLastPathname = pathname;

                window.scrollTo({ top: 0, left: 0 });
            }
        });
    }
}

export default Router;