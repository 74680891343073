/* eslint-disable */
import { getViewedProductData } from '../util/Klaviyo';

const registerViewedProduct = (args, callback, instance) => {
    callback.apply(instance, args);

    const {
        viewedProductEvent
    } = instance.props;

    if (!viewedProductEvent) return;

    const [prevProps] = args;

    const {
        product: {
            sku
        },
        product,
        brandAttribute,
        baseLinkUrl
    } = instance.props;

    const {
        product: {
            sku: prevSku
        }
    } = prevProps;

    if (sku !== prevSku) {
        pushViewedProduct(product, baseLinkUrl, brandAttribute);
    }
}

const pushViewedProduct = (product, baseLinkUrl, brandAttribute) => {
    const _learnq = window._learnq || [];

    console.log(product);
    console.log(_learnq);

    if (!product) return;

    const productData = getViewedProductData(product, baseLinkUrl, brandAttribute);

    _learnq.push(['track', 'Viewed Product', productData]);

    const recentProductData = {
        Title: productData.ProductName,
        ItemId: productData.ProductID,
        Categories: productData.Categories,
        ImageUrl: productData.ImageURL,
        Url: productData.URL,
        Metadata: {
            Brand: productData.Brand,
            Price: productData.Price,
            CompareAtPrice: productData.CompareAtPrice
        }
    }

    _learnq.push(['trackViewedItem', recentProductData]);
}

const mapStateToPropsProductPageContainer = (args, callback, instance) => {
    const [state] = args;

    return {
        ...callback.apply(instance, args),
        brandAttribute: state.ConfigReducer.klaviyo_general_brand_attribute,
        baseLinkUrl: state.ConfigReducer.base_link_url,
        viewedProductEvent: state.ConfigReducer.klaviyo_events_viewed_product
    }
}

export default {
    'Route/ProductPage/Container': {
        'member-function': {
            'componentDidUpdate': registerViewedProduct
        }
    },
    'Route/ProductPage/Container/mapStateToProps': {
        'function': mapStateToPropsProductPageContainer
    }
}