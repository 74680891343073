import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { CategoryTreeType } from 'Type/Category';

import CategoryMenu from './CategoryMenu.component'

import { updateCategoriesTopLevel } from '../../store/CategoryMenu/CategoryMenu.action'

export const CategoryMenuDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    '../../store/CategoryMenu/CategoryMenu.dispatcher'
);

/** @namespace Component/CategoryMenu/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    category: state.CategoryReducer.category,
    categories: state.CategoryMenuReducer.categories,
    activeId: state.CategoryMenuReducer.id
});

/** @namespace Component/CategoryMenu/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    updateCategories: options => CategoryMenuDispatcher.then(
        ({ default: dispatcher }) => dispatcher.handleData(dispatch, options)
    ),
    updateCategoriesTopLevel: category => dispatch(updateCategoriesTopLevel(category))
});

/** @namespace Component/CategoryMenu/Container */
export class CategoryMenuContainer extends PureComponent {
    static propTypes = {
        category: CategoryTreeType.isRequired,
    }

    __construct(props) {
        super.__construct(props);
    }

    componentDidMount(){
        const { category: { id }, categories, activeId} = this.props;
        if (id && id != activeId){
            this.updateTree();
        }
    }

    componentDidUpdate(prevProps){
        const { category: { id }, categories } = this.props;
        const { category: { id: prevId }} = prevProps;
        if (id != prevId){
            this.updateTree();
        }
    }

    updateTree(){
        const { category: { breadcrumbs = false }, category, updateCategories, updateCategoriesTopLevel } = this.props;
        if (!breadcrumbs){
            updateCategoriesTopLevel(category);
            return;
        }
        const categoryIds = breadcrumbs.map((crumb) => crumb.category_id);
        if (categoryIds.length){
            updateCategories({categoryIds: [...categoryIds, category.id], category: category})
        }
    }

    render(){
        const { id: active } = this.props.category;
        const { categories: categoryTree } = this.props;
        if (!categoryTree){
            return null;
        }
        return <CategoryMenu category={categoryTree} active={active} />
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CategoryMenuContainer);