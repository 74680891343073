/* eslint-disable */

// import Header from 'SourceComponent/Header/Header.component';
// import './Header.override.style.scss';
// export default Header;

import { Header as HoeksHeader } from 'HoeksComponent/Header/Header.component';
import NavigationTabs from 'HoeksComponent/NavigationTabs';
import CmsBlock from 'Component/CmsBlock';

import './Header.override.style.scss';

/** @namespace Component/Header/Component */
export class Header extends HoeksHeader {
    renderContacts() {
        return (
            <></>
        );
    }

    renderCustomTopMenu() {
        const { device } = this.props;
        if (device.isMobile) {
            return null;
        }
        return (
            <>
                <div block="Header" elem="TopMenu">
                    <CmsBlock identifier={ "header-usp" } />
                </div>
            </>
        );
    }

    renderTopMenu() {
        return (
            <>
                { this.renderCustomTopMenu() }
                <NavigationTabs />
            </>
        )
    }

    renderSearchField(isVisible = false) {
        const {
            isCheckout,
        } = this.props;

        if (isCheckout) {
            return null;
        }

        return (
            <div block="ClerkSearch" elem="InputWrapper">
                <input
                    id="search-field"
                    block="ClerkSearch"
                    elem="Input"
                    mods={{ isActive: true }}
                    autoComplete="off"
                    aria-label={__('Search')}
                />
                <span block="ClerkSearch" elem="Input-Icon"></span>
            </div>
        );
    }


    renderMap = {
        cancel: this.renderCancelButton.bind(this),
        back: this.renderBackButton.bind(this),
        close: this.renderCloseButton.bind(this),
        share: this.renderShareWishListButton.bind(this),
        title: this.renderTitle.bind(this),
        logo: this.renderLogo.bind(this),
        search: this.renderSearchField.bind(this),
        account: this.renderAccount.bind(this),
        minicart: this.renderMinicart.bind(this),
        compare: this.renderComparePageButton.bind(this),
        clear: this.renderClearButton.bind(this),
        edit: this.renderEditButton.bind(this),
        ok: this.renderOkButton.bind(this)
    };



}

export default Header;
