/* eslint-disable */
import {
    SIMPLE,
    BUNDLE,
    CONFIGURABLE,
    GROUPED
} from 'Util/Product';

export const getViewedProductData = (product, baseLinkUrl, brandAttribute) => {
    const {
        categories = [],
        media_gallery_entries = [],
        attributes = {},
        name = '',
        id = '',
        sku = '',
        url = '',
        price_range: {
            minimum_price: {
                final_price: {
                    value = 0
                } = {}
            } = {}
        } = {}
    } = product;

    const categoryNames = getCategoryNames(categories);
    const imageUrl = getImageUrl(media_gallery_entries);
    const [_, brand] = Object.entries(attributes).find(([_, attribute]) => attribute.attribute_code === brandAttribute) || [];
    const {
        attribute_options: attributeOptions = {}
    } = (brand ?? {});
    const brandName = Object.entries(attributeOptions).map(([_, option]) => option.label || '').join(', ');

    const productUrl = getUrl(url, baseLinkUrl);

    const data = {
        ProductName: name,
        ProductID: id,
        SKU: sku,
        Categories: categoryNames,
        ImageURL: imageUrl,
        URL: productUrl,
        Brand: brandName,
        Price: value,
        CompareAtPrice: 0
    };

    console.log(data);

    return data;
}

export const getAddedToCartData = (props) => {
    const {
        product: {
            type_id,
            name = '',
            id = '',
            sku = '',
            categories = [],
            media_gallery_entries = [],
            url = '',
            price_range: {
                minimum_price: {
                    final_price: {
                        value = 0
                    } = {}
                } = {}
            } = {},
            variants
        },
        baseLinkUrl,
        quantity = 1,
        cartGrandTotal,
        cartItems,
        configurableVariantIndex
    } = props;

    const categoryNames = getCategoryNames(categories);
    const imageUrl = getImageUrl(media_gallery_entries)
    const productUrl = getUrl(url, baseLinkUrl);
    const checkoutUrl = getUrl('/checkout', baseLinkUrl);
    const productNames = getCartItemNames(cartItems);
    const items = getCartItems(cartItems, baseLinkUrl);
    let productSku = sku;
    let productId = id;

    if (type_id === CONFIGURABLE) {
        const {
            sku: variantSku,
            id: variantId
        } = variants[configurableVariantIndex];

        productSku = variantSku;
        productId = variantId;
    }

    const data = {
        '$value': cartGrandTotal,
        AddedItemProductName: name,
        AddedItemProductID: productId,
        AddedItemSKU: productSku,
        AddedItemCategories: categoryNames,
        AddedItemImageURL: imageUrl,
        AddedItemURL: productUrl,
        AddedItemPrice: value,
        AddedItemQuantity: quantity,
        ItemNames: productNames,
        CheckoutURL: checkoutUrl,
        Items: items
    };

    console.log(data);

    return data;
}

export const getStartedCheckoutData = (props) => {
    const {
        totals: {
            grand_total,
            items: cartItems
        } = {},
        baseLinkUrl
    } = props;

    const productNames = getCartItemNames(cartItems);
    const items = getCartItems(cartItems, baseLinkUrl);
    const url = getUrl('/checkout', baseLinkUrl);
    const allCategories = cartItems.reduce((acc, { product: { categories = [] } } = {}) => [...acc, ...categories], []);
    const allCategoriesFiltered = getCategoryNames(allCategories);

    const data = {
        '$event_id': Date.now(),
        '$value': grand_total,
        ItemNames: productNames,
        CheckoutURL: url,
        Categories: allCategoriesFiltered,
        Items: items,
        Extra: {
            GrandTotal: grand_total,
            SubTotal: grand_total
        }
    }

    console.log(data);

    return data;
}

const getCategoryNames = (categories) => categories.map(category => category.name).reduce((acc, val) => acc.some(e => e === val) ? acc : [...acc, val], []);

const getImageUrl = (galleryEntries) => `${galleryEntries.find(entry => (entry.types || []).some(type => type === 'image') || entry?.media_type === 'image')?.base?.url}`;

const getUrl = (url, baseLinkUrl) => url !== '' ? `${baseLinkUrl.endsWith('/') ? baseLinkUrl.slice(0, baseLinkUrl.length - 1) : baseLinkUrl}${url}` : '';

const getCartItemNames = (cartItems) => cartItems.map(({ product: { name } = {} } = {}) => name);

const getCartItems = (cartItems, baseLinkUrl) => {
    return cartItems.map(cartItem => {
        const {
            qty,
            sku: itemSku,
            price,
            row_total,
            product: {
                type_id,
                name,
                id,
                categories = [],
                small_image: {
                    url: imageUrl
                },
                url = '',
                variants
            }
        } = cartItem;

        const categoryNames = getCategoryNames(categories);
        const productUrl = getUrl(url, baseLinkUrl);
        let productSku = itemSku;
        let productId = id;

        if (type_id === CONFIGURABLE) {
            const variant = variants[variants.findIndex(({ sku }) => sku === itemSku || itemSku.includes(sku))];
            const {
                sku: variantSku,
                id: variantId
            } = variant;
            productSku = variantSku,
            productId = variantId;
        }

        // const data = {
        //     ProductID: productId,
        //     SKU: productSku,
        //     ProductName: name,
        //     Quantity: qty,
        //     ItemPrice: price,
        //     RowTotal: row_total,
        //     ProductURL: productUrl,
        //     ImageURL: imageUrl,
        //     ProductCategories: categoryNames
        // }
        const data = {
            ItemID: productId,
            SKU: productSku,
            Name: name,
            Qty: qty,
            ItemPrice: price,
            RowTotal: row_total,
            ProductURL: productUrl,
            ImageURL: imageUrl,
            FirstImageURL: imageUrl,
            ProductCategories: categoryNames,
            SimpleName: name,
        }

        return data;
    })
}