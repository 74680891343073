/* eslint-disable */
import { PureComponent } from 'react';
import Html from 'Component/Html';

/** @namespace Component/KlaviyoForms/Component */
export class KlaviyoForms extends PureComponent {
    render() {
        const { script } = this.props;

        if (!script) {
            return null;
        }

        console.log(script);

        return (
            <Html content={ script } />
        )
    }
}

export default KlaviyoForms;