/* eslint-disable */
import { BEFORE_ITEMS_TYPE } from 'Component/Router/Router.config';
import KlaviyoForms from '../component/KlaviyoForms';

const addKlaviyoForms = (member, instance) => {
    return [
        ...member,
        {
            component: <KlaviyoForms />,
            position: 17
        }
    ]
}

export default {
    'Component/Router/Component': {
        'member-property': {
            [BEFORE_ITEMS_TYPE]: addKlaviyoForms
        }
    }
}