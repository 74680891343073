import { PureComponent } from 'react';
import EanFields from '../component/EanFields';
import { EAN } from '../component/EanFields/EanFields.config';

export class CheckoutPaymentsPlugin extends PureComponent {
    addEanData = (member, context) => {
        return {
            ...member,
            [EAN]: this.getEanData.bind(context)
        }
    }

    getEanData() {
        const asyncData = new Promise((resolve, reject) => {
            resolve({});
        });
        return { asyncData: asyncData };
    }

    addEanRender = (member, context) => {
        return {
            ...member,
            [EAN]: this.renderEanFields.bind(context)
        }
    }

    renderEanFields() {
        return (<EanFields />);
    }
}

const onSuccess = (args, callback, instance) => {
    console.log(args);
    const { paymentMethod } = instance.state;
    if (paymentMethod === EAN){
        const [fields] = args;
        const { ean_number, requisition_number, vat_id, ...trimmedArgs } = fields; 
        const asyncData = [{ ean_number, requisition_number, vat_id }];
        return callback(trimmedArgs, asyncData);
    }
    return callback(...args);
}

const getEanData = (args, callback, instance) => {
    const { paymentMethod: code } = instance.state;
    if (code === EAN) {
        const [asyncData] = args;
        const [{ ean_number, requisition_number, vat_id }] = asyncData;
        return {
            code,
            additional_data: {
                ean_number,
                requisition_number,
                vat_id
            }
        };
    }
    return callback(...args);
}

const { addEanRender, addEanData, aroundContainerFunctions, aroundRenderPayments } = new CheckoutPaymentsPlugin();

export default {
    'Component/CheckoutBilling/Container': {
        'member-function': {
            onBillingSuccess: onSuccess,
            _getPaymentData: getEanData
        }
    },
    'Component/CheckoutPayments/Component': {
        'member-property': {
            paymentRenderMap: addEanRender
        }
    },
    'Component/CheckoutPayments/Container': {
        'member-property': {
            // dataMap: addEanData
        }
    }
};