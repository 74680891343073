/* eslint-disable */
const getQuery = (args, callback, instance) => {
    const urlEncodedArgs = args.map(arg => {
        const {
            args: {
                filter: {
                    productSKU
                } = {}
            } = {}
        } = arg;
        if (productSKU) {
            return {
                ...arg,
                args: {
                    filter: {
                        productSKU: encodeURIComponent(productSKU)
                    }
                }
            };
        } else {
            return arg;
        }
    });
    return callback.apply(instance, urlEncodedArgs);
}
export default {
    'Query/ProductList': {
        'member-function': {
            getQuery
        }
    }
};