/* eslint-disable */
import KlaviyoFormWidget from './KlaviyoFormWidget.component';
import { PureComponent } from 'react';

/** @namespace Component/KlaviyoFormWidget/Container */
export class KlaviyoFormWidgetContainer extends PureComponent {
    render() {
        return (
            <KlaviyoFormWidget { ...this.props } />
        )
    }
}

export default KlaviyoFormWidgetContainer;