/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

/* eslint-disable no-restricted-syntax */
/* eslint-disable react/no-array-index-key */
/* eslint-disable */
// Disabled due placeholder needs

import { ProductActions as SourceProductActions} from 'HoeksComponent/ProductActions/ProductActions.component';
import TextPlaceholder from 'Component/TextPlaceholder';

import './ProductActions.override.style';

export class ProductActions extends SourceProductActions {
    renderSkuAndStock() {
        const {
            product,
            product: { variants },
            configurableVariantIndex,
            showOnlyIfLoaded,
            getStockDaysLabel
        } = this.props;

        const productOrVariant = variants && variants[configurableVariantIndex] !== undefined
            ? variants[configurableVariantIndex]
            : product;

        const { sku, stock_status } = productOrVariant;

        return (
            <section
              block="ProductActions"
              elem="Section"
              mods={ { type: 'sku' } }
              aria-label="Product SKU and availability"
            >
                { showOnlyIfLoaded(
                    sku,
                    (
                        <>
                            <span block="ProductActions" elem="Sku">
                                SKU:
                            </span>
                            <span block="ProductActions" elem="Sku" itemProp="sku">
                                { `${ sku }` }
                            </span>
                            <span block="ProductActions" elem="Stock">
                                { this.renderStock(stock_status) }
                            </span>
                            <span block="ProductAction" elem="StockDays">
                                { getStockDaysLabel() }
                            </span>
                        </>
                    ),
                    <TextPlaceholder />
                ) }
            </section>
        );
    }
}

export default ProductActions;
