/* eslint-disable */
import { connect } from 'react-redux';
import { NavigationTabsContainer as SourceNavigationTabsContainer } from 'SourceComponent/NavigationTabs/NavigationTabs.container';
import NavigationTabs from 'Component/NavigationTabs/NavigationTabs.component';
import {
    CATEGORY,
    MENU
} from 'Component/Header/Header.config';
export * from 'SourceComponent/NavigationTabs/NavigationTabs.container';
import { changeNavigationState, goToPreviousNavigationState } from 'Store/Navigation/Navigation.action';
import { hideActiveOverlay, toggleOverlayByKey } from 'Store/Overlay/Overlay.action';
import { BOTTOM_NAVIGATION_TYPE, TOP_NAVIGATION_TYPE } from 'Store/Navigation/Navigation.reducer';

/** @namespace Component/NavigationTabs/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showOverlay: (overlayKey) => dispatch(toggleOverlayByKey(overlayKey)),
    hideActiveOverlay: () => dispatch(hideActiveOverlay()),
    setNavigationState: (stateName) => dispatch(changeNavigationState(BOTTOM_NAVIGATION_TYPE, stateName)),
    goToPreviousHeaderState: () => dispatch(goToPreviousNavigationState(TOP_NAVIGATION_TYPE)),
    goToPreviousNavigationState: () => dispatch(goToPreviousNavigationState(BOTTOM_NAVIGATION_TYPE)),
    changeHeaderState: (state) => dispatch(changeNavigationState(TOP_NAVIGATION_TYPE, state))
});

/** @namespace Component/NavigationTabs/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    navigationState: state.NavigationReducer[BOTTOM_NAVIGATION_TYPE].navigationState,
    headerState: state.NavigationReducer[TOP_NAVIGATION_TYPE].navigationState,
    device: state.ConfigReducer.device,
    cartTotals: state.CartReducer.cartTotals,
    noMatch: state.NoMatchReducer.noMatch,
    header_logo_src: state.ConfigReducer.header_logo_src
});

export class NavigationTabsContainer extends SourceNavigationTabsContainer {
    onMenuButtonClick() {
        const { showOverlay, goToPreviousHeaderState, hideActiveOverlay, changeHeaderState} = this.props;


        const { showMenu = false } = this.state;
        if (showMenu) {
            this.closeOverlayMenu();
        }
        else {
            changeHeaderState({
                name: 'default',
                isHiddenOnMobile: false
            });
            showOverlay(MENU);
        }
        this.setState({ showMenu: !showMenu });
    }

    handleMobileRouteChange(history) {
        this.closeOverlayMenu(false);
        return super.handleMobileRouteChange(history);
    }

    closeOverlayMenu(revertHeader = true){
        const { hideActiveOverlay, goToPreviousHeaderState } = this.props;
        hideActiveOverlay();
        this.setState({ showMenu: false });
        if (revertHeader){
            goToPreviousHeaderState();
        }
    }

    render() {
        const { showMenu } = this.state;
        return (
            <NavigationTabs
                { ...this.props }
                { ...this.containerFunctions }
                showMenu={ showMenu }
                closeOverlayMenu={ this.closeOverlayMenu.bind(this) }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NavigationTabsContainer);