import { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { CategoryTreeType } from 'Type/Category';
import ExpandableContent from 'Component/ExpandableContent';

import CategoryMenuItem from '../CategoryMenuItem'

import './CategoryMenu.style';

/** @namespace Component/CategoryMenu/Component */
export class CategoryMenu extends PureComponent {
    static propTypes = {
        category: CategoryTreeType.isRequired,
        active: PropTypes.number
    }

    static defaultProps = {
        active: 0
    }

    __construct(props) {
        super.__construct(props);
    }

    renderSubcategory = category => {
        const { active } = this.props;
        return (<CategoryMenuItem key={category.id+"-"+active} category={category} active={active} />);
    }

    renderSubcategories() {
        const { category: { children }, isContentExpanded } = this.props;

        if (!children){
            return null;
        }
        return (
            <ExpandableContent
                key="category"
                heading={__('Category')}
                mix={ {
                    block: 'ProductConfigurableAttributes',
                    elem: 'Expandable',
                    mix: {block: 'CategoryMenu'}
                } }
                isContentExpanded={ isContentExpanded }
            >
            { children.map(this.renderSubcategory) }
            </ExpandableContent>
        )
    }

    render(){
        return this.renderSubcategories();
    }
}

export default CategoryMenu;
