/* eslint-disable */
import { ProductActionsContainer as SourceProductActionsContainer } from 'SourceComponent/ProductActions/ProductActions.container';

import { connect } from 'react-redux';

/** @namespace Component/ProductActions/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    groupedProductQuantity: state.ProductReducer.groupedProductQuantity,
    device: state.ConfigReducer.device
});

/** @namespace Component/ProductActions/Container */
export class ProductActionsContainer extends SourceProductActionsContainer {

    containerFunctions = {
        showOnlyIfLoaded: this.showOnlyIfLoaded.bind(this),
        onProductValidationError: this.onProductValidationError.bind(this),
        getIsOptionInCurrentVariant: this.getIsOptionInCurrentVariant.bind(this),
        setQuantity: this.setQuantity.bind(this),
        setGroupedProductQuantity: this._setGroupedProductQuantity.bind(this),
        clearGroupedProductQuantity: this._clearGroupedProductQuantity.bind(this),
        getIsConfigurableAttributeAvailable: this.getIsConfigurableAttributeAvailable.bind(this),
        getStockDaysLabel: this.getStockDaysLabel.bind(this)
    };

    getStockDaysLabel() {
        const { product: { attributes } } = this.props;
        var stockLabel = '';

        if (attributes == undefined) return stockLabel;

        Object.values(attributes).forEach(attribute => {
            const { attribute_code, attribute_label, attribute_options, attribute_value } = attribute

            if (attribute_code == "stock_days") {
                const { label } = attribute_options[attribute_value];
                stockLabel = ` ${attribute_label} ${label}`;
            }
        });

        return stockLabel;
    }
}

/** @namespace Component/ProductActions/Container/mapDispatchToProps */
// eslint-disable-next-line no-unused-vars
export const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ProductActionsContainer);