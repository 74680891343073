  
const addBaseFields = (args, callback) => {
    return [
        ...callback(...args),
        'category_id'
    ];
};

const addChildrenCount = (args, callback) => {
    return [
        ...callback(...args),
        'children_count',
        'include_in_menu',
        'position'
    ];
};

export default {
    'Query/Category': {
        'member-function': {
            _getBreadcrumbFields: addBaseFields,
            _getDefaultFields : addChildrenCount
        }
    }
};