/* eslint-disable import/no-cycle */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

 import Event, { EVENT_GTM_META_UPDATE } from '../../../util/Event';
 import BaseEvent from './BaseEvent.event';
 import { isEventEnabled } from '../../../util/EventConfig';
 
 export const PAGEVIEW_EVENT_DELAY = 500;
 
 /**
  * GTM PWA General event
  *
  * On: page load, page change location
  */
 class PageView extends BaseEvent {
     /**
      * If already loading data, do not do second request
      *
      * @type {boolean}
      */
     isLoadingData = false;
 
     /**
      * Set base event call delay
      *
      * @type {number}
      */
     eventHandleDelay = 0;
 
     /**
      * Current meta data
      *
      * @type {{}}
      */
     currentMeta = { title: '' };
 
     /**
      * Bind PWA event handling
      */
     bindEvent() {
         // eslint-disable-next-line prefer-destructuring
         const history = this.getGTM().props.history;
 
         // eslint-disable-next-line fp/no-let
         let prevLocation = history.location;
 
         history.listen((location) => { // On page change
            console.log(location);
             const { pathname, search } = location;
             const { pathname: prevPathname, search: prevSearch } = prevLocation;
 
             // prevents from firing general on filter change (PLP) and on attribute change (PDP)
             if (
                 pathname === prevPathname && search === prevSearch
             ) return;
 
             prevLocation = location;
 
             setTimeout(() => {
                 this.handle({location});

		if(ADDWISH_PARTNER_NS){
                     ADDWISH_PARTNER_NS.api.reload()
                 }

             }, PAGEVIEW_EVENT_DELAY);
         });
 
         // Receive current meta
        //  Event.observer(EVENT_GTM_META_UPDATE, (meta) => {
        //      this.currentMeta = meta;
        //  });
     }
 
     saveCartDataToStorage() {
         const storage = this.getStorage() || {};
         storage.cartLast = storage.cart;
         storage.cart = this.getAppState().CartReducer.cartTotals.items;
         this.setStorage(storage);
     }
 
     /**
      * Handler General
      */
     handler({location}) {
         this.pushEventData({
            virtualPath: location.pathname + location.search
         });
     }
 
     /**
      * Get page path
      *
      * @return {string}
      */
     getPagePath() {
         const {
             props: {
                 history: {
                     location: {
                         pathname
                     } = {}
                 } = {}
             } = {}
         } = this.getGTM();
 
         return pathname || '';
     }
 
         /**
      * Get page title
      *
      * @return {string}
      */
     getPageTitle() {
         const {
             MetaReducer: {
                 title,
                 default_title
             } = {}
         } = this.getAppState();
 
         return title || default_title;
     }
 
     /**
      * Get current store view
      *
      * @return {string}
      */
     getStoreView() {
         return this.getAppState().ConfigReducer.code || '';
     }
 
     /**
      * @param {*} item
      */
     getQuantity({ qty }) {
         return qty;
     }
 
     /**
      * Get current language
      *
      * @return {string}
      */
     getLanguage() {
         return this.getAppState().ConfigReducer.locale || '';
     }
 
     /**
      * Get current signed in customer id
      *
      * @return {string}
      */
     getCustomerId() {
         return this.isSignedIn() ? this.getAppState().MyAccountReducer.customer.id || '' : '';
     }
 
     /**
      * Get customer city
      *
      * @return {string}
      */
     getCountryName() {
         return this.getAppState().ConfigReducer.default_country || '';
     }
 }
 
 export default PageView;
 
